const $ = require('jquery');
// 渲染底部
function renderFooter () {
    const footerList = juheMenus.site_footer
    const innerList = juheMenus.site_inner_navigate
    //获取模版里面的内容
    let source = $("#footer-template").html();
    //模版渲染
    let template = Handlebars.compile(source);
    let html = template(footerList);
    //获取模版里面的内容
    let source2 = $("#footer-template2").html();
    //模版渲染
    let template2 = Handlebars.compile(source2);
    let html2 = template2(innerList);
    //模版装载到dom节点上
    $(".footer-list").html(html);
    // 渲染底部备案信息
    $(".footerDoc").html(juheMenus.site_copyright[0].description)  
    $(".footerInfo").html(html2)
}

$(document).ready(function () {
    // 渲染底部
    renderFooter()
});


/*
  // 套用模板
  html...
   <!-- 动态渲染底部备案信息 -->
    <link rel="stylesheet" href="https://www.juhe.cn/css/v7/headerFooter/footer-white1.css?_t=1583396062366" />
      <script src="https://juhecdn.oss-cn-hangzhou.aliyuncs.com/jquery/juhe.menus.js"></script>
    <!-- 模板引擎 -->
    <script src="https://cdn.bootcss.com/handlebars.js/4.0.6/handlebars.js"></script>
    <div id="footer">
        <footer class="footer">
            <div class="footer-container">
                <div class="footer-top clearfix">
                    <div class="footer-link">
                        <div class="footer-list"></div>
                    </div>
                    <div class="footer-wechat">
                        <p class="wechat-title">微信公众号</p>
                        <p class="wechat-name">juhe_cn</p>
                        <p class="wechat-pic"></p>
                    </div>
                </div>
                <!-- 动态渲染底部备案信息 -->
                <div class="footerDoc"></div>
            </div>
        </footer>
    </div>

  js.....


  <script id="footer-template" type="text/x-handlebars-template">
        {{#each this}}
        <dl>
            <dt>{{name}}</dt>
            {{#each children}}
                <dd>
                    <a href="{{link}}" class="click-report-log"
                    log-data='{{id}}' target="_blank"
                    rel="noopener noreferrer">{{name}}</a>
                </dd>
            {{/each}}
        </dl>
        {{/each}}
    </script>

 */